{
  "apiKey": "4_DnUrJaT5ESqFobvg0J1BGA",
  "clientId": "dhkhRg2lHo5o6YxD_HCRfqrR",
  "clientSecret": "vo7Xxoc0WEhLbDHRJ_oHLviAgZMAE5_sufHhGyuBnnFucyH92y2LMoDiAG6iPJyJMfF5woRXvq6TTmaG8JmmDQ",
  "issuer_url": "https://fidm.eu1.gigya.com/oidc/op/v1.0",
  "responseType": "code",
  "scope": "openid",
  "redirect_path": "/",
  "version": "v1",
  "grant_type": "authorization_code"
}
